<template>
    <div class="login">
        <div class="login-signin" :class="{ 'add-opacity': busy }">
            <h4>Login</h4>
            <div class="input">
                <input v-model="form.username" type="text" placeholder="Username" name="username" id="username" required />
            </div>
            <div class="input">
                <input v-model="form.password" type="password" placeholder="Password" name="password" id="password" required />
            </div>
            <p class="error error-message">{{ error }}</p>
            <div class="login-button">
                <button @click="signIn" :disabled="isEmptyString(form.username) || isEmptyString(form.password)">Login</button>
            </div>
            <div class="login-button-secondary">
                <button @click="goToResetPassword">Reset Password</button>
            </div>
        </div>
        <div class="sp sp-circle sp-center" v-if="busy"></div>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';

export default {
    data() {
        return {
            busy: false,
            error: null,
            form: {
                username: '',
                password: '',
            }
        };
    },
    methods: {
        goToResetPassword() {
            this.$router.push('/reset');
        },
        signIn() {
            this.error = null;
            this.busy = true;

            Auth.signIn(this.form.username, this.form.password)
                .then((data) => {
                    this.$store.commit('login', data);
                    
                    this.busy = false;
+                   this.$router.push('/portal')
                })
                .catch((err) => {
                    this.error = err.message;
                    this.busy = false;
                });
        },
    },
};
</script>
